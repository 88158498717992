import { DEFAULT_FLAGS } from '@capturi/feature-flags'
import { List, ListItem, useToast } from '@capturi/ui-components'
import {
  Box,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Switch,
  Text,
} from '@chakra-ui/react'
import pick from 'lodash/pick'
import { FC, ReactElement, memo, useCallback, useMemo } from 'react'

import { Title } from '../../../../utils'
import { useFeatureFlags, useUpdateFeatureFlag } from './api/useFeatureFlags'

type Props = { organizationUid: string }
type Flags = { [key: string]: boolean }
type Flag = { key: string; value: boolean }

const convertToArray = (flags: Flags) => {
  const flagList = Object.entries(flags).map(([key, value]) => ({ key, value }))

  return Object.groupBy(flagList, (flag) => {
    const ungroupedFlags = 'Ungrouped flags'
    if (flag.key.startsWith('_')) {
      return ungroupedFlags
    }
    if (flag.key.includes('_')) {
      return flag.key.split('_')[0]
    }
    return ungroupedFlags
  })
}

const handleKeyFormatting = (key: string): string => {
  if (key.startsWith('_')) {
    return key
  }
  if (key.includes('_')) {
    return key.split('_')[1]
  }
  return key
}

const FeatureFlags: FC<Props> = ({ organizationUid }) => {
  const toast = useToast()

  const { data } = useFeatureFlags(organizationUid)
  const { mutate: updateFeatureFlag, isPending } =
    useUpdateFeatureFlag(organizationUid)
  const flags = useMemo(() => {
    if (data == null) return convertToArray(DEFAULT_FLAGS)
    const mergedFlags = {
      ...DEFAULT_FLAGS,
      ...data,
    }
    // Omit flags that are no longer in use but still returned by the api
    const flags = pick(mergedFlags, Object.keys(DEFAULT_FLAGS))
    return convertToArray(flags)
  }, [data])

  const handleToggle = useCallback(
    (key: string, value: boolean): void => {
      updateFeatureFlag(
        { key, value },
        {
          onError: (error) => {
            toast({ status: 'error', title: error.message })
          },
        },
      )
    },
    [updateFeatureFlag, toast],
  )

  const renderRow = ({ key, value }: Flag): ReactElement => {
    return (
      <ListItem key={key} hasDivider>
        <FormControl display="flex" alignItems="center">
          <Switch
            size="sm"
            color="primary"
            id={key}
            isChecked={value}
            onChange={() => handleToggle(key, !value)}
            mr={2}
            isDisabled={isPending}
          />
          <FormLabel htmlFor={key} fontWeight="normal" m={0}>
            {handleKeyFormatting(key)}
          </FormLabel>
        </FormControl>
      </ListItem>
    )
  }

  return (
    <Card>
      <CardBody>
        <Title>Feature Flags</Title>
        <List>
          {Object.entries(flags).map(([key, value]) => (
            <Box key={key}>
              <Text fontWeight="medium">{key}</Text>
              <List>{value?.map(renderRow)}</List>
            </Box>
          ))}
        </List>
      </CardBody>
    </Card>
  )
}

export default memo(FeatureFlags)
