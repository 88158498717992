import request, { ResponseError } from '@capturi/request'
import {
  type UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AdditionalOrg } from './tabs/Config/ExternalApi/MultiOrgConfigModal'

export type ExternalIntegration = {
  tokenId: string
  tokenHash: string
  organizationUid: string
  created: string
  name: string
  additionalOrganizations: AdditionalOrg[] | undefined
  multipleOrganizations: boolean
}

type AdditionalOrganizationsRequestModel = {
  tokenId: string
  name: string
  organizationUid: string
  additionalOrganizations: AdditionalOrg[]
}

type MultipleOrganizationsRequestModel = {
  tokenId: string
  multipleOrganizations: boolean
}

export const useExternalApi = <T = ExternalIntegration[]>(
  select?: ((data: ExternalIntegration[]) => T) | undefined,
): UseQueryResult<T, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'externalIntegration'],
    queryFn: () =>
      request.get<ExternalIntegration[]>('superpowers/external-api/list/all'),
    select,
  })

const byOrgUidSelector =
  (organizationUid: string) => (data: ExternalIntegration[]) =>
    data.filter((d) => d.organizationUid === organizationUid)

export const useOrganizationExternalApi = (
  organizationUid: string,
): UseQueryResult<ExternalIntegration[], ResponseError> =>
  useExternalApi(byOrgUidSelector(organizationUid))

export const useCreateToken = (
  organizationUid: string,
): UseMutationResult<
  { secret: string },
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (name: string) =>
      request.post<{ secret: string }>(
        `superpowers/external-api/${organizationUid}`,
        {
          json: {
            name,
          },
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'externalIntegration'],
      })
    },
  })
}
export const useDeleteToken = (
  organizationUid: string,
): UseMutationResult<null, ResponseError, string, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (token) =>
      request.delete(`superpowers/external-api/${organizationUid}/${token}`),
    onSuccess: (_, token) => {
      queryClient.setQueryData<ExternalIntegration[]>(
        ['superpowers', 'externalIntegration'],
        (oldData = []) => oldData.filter((d) => d.tokenId !== token),
      )
    },
  })
}

export const useUpdateTokenName = (
  organizationUid: string,
): UseMutationResult<
  ExternalIntegration,
  ResponseError,
  ExternalIntegration,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload) =>
      request.put(
        `superpowers/external-api/${organizationUid}/${payload.tokenId}/name`,
        {
          json: {
            name: payload.name,
          },
        },
      ),
    onSuccess: (_, token) => {
      queryClient.setQueryData<ExternalIntegration[]>(
        ['superpowers', 'externalIntegration'],
        (oldData = []) =>
          oldData.map((d) =>
            d.tokenId === token.tokenId ? { ...d, name: token.name } : d,
          ),
      )
    },
  })
}

export const useUpdateTokenWithAdditionalOrgs = (
  organizationUid: string,
): UseMutationResult<
  ExternalIntegration,
  ResponseError,
  AdditionalOrganizationsRequestModel,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload) =>
      request.put(
        `superpowers/external-api/${organizationUid}/${payload.tokenId}/additional-organizations`,
        {
          json: {
            additionalOrganizations: payload.additionalOrganizations,
          },
        },
      ),
    onSuccess: (_, token) => {
      queryClient.setQueryData<ExternalIntegration[]>(
        ['superpowers', 'externalIntegration'],
        (oldData = []) =>
          oldData.map((d) =>
            d.tokenId === token.tokenId
              ? { ...d, additionalOrganizations: token.additionalOrganizations }
              : d,
          ),
      )
    },
  })
}

export const useSetMultipleOrganizations = (
  organizationUid: string,
): UseMutationResult<
  boolean,
  ResponseError,
  MultipleOrganizationsRequestModel,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ multipleOrganizations, tokenId }) =>
      request.put(
        `superpowers/external-api/${organizationUid}/${tokenId}/multiple-organizations`,
        {
          json: {
            multipleOrganizations,
          },
        },
      ),
    onSuccess: (_, payload) => {
      queryClient.setQueryData<ExternalIntegration[]>(
        ['superpowers', 'externalIntegration'],
        (oldData = []) =>
          oldData.map((d) =>
            d.tokenId === payload.tokenId
              ? { ...d, multipleOrganizations: payload.multipleOrganizations }
              : d,
          ),
      )
    },
  })
}
