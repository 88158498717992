import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import type { Item } from './Column'
import { formatTime } from './formatTime'

type Props = {
  stats: Item['stats']
  integrationKey: string
} & BaseModalComponentProps

export const DetailsModal: FC<Props> = ({ integrationKey, stats, onClose }) => {
  return (
    <Modal isOpen onClose={onClose} size="6xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{integrationKey}</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Most Recent Record Date</Th>
                    <Th>Continuation Token</Th>

                    <Th isNumeric>fetched</Th>
                    <Th isNumeric>created</Th>
                    <Th isNumeric>failed</Th>
                    <Th isNumeric>exists</Th>
                    <Th isNumeric>skipped</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {stats?.map(
                    (
                      {
                        continuationToken,
                        created,
                        exists,
                        failed,
                        fetched,
                        mostRecentRecordDate,

                        skipped,
                      },
                      idx,
                    ) => (
                      <Tr key={idx} _hover={{ background: 'gray.100' }}>
                        <Td>
                          {mostRecentRecordDate.toLocaleString('da-DK')}{' '}
                          <chakra.span color={'success'}>
                            +{calcTimeDifference(stats, idx)}
                          </chakra.span>
                        </Td>
                        <Td>{continuationToken.toLocaleString('da-DK')}</Td>
                        <Td isNumeric>{fetched}</Td>
                        <Td
                          color={created === 0 ? 'text' : 'success'}
                          isNumeric
                        >
                          {created || '-'}
                        </Td>
                        <Td color={failed === 0 ? 'text' : 'danger'} isNumeric>
                          {failed || '-'}
                        </Td>
                        <Td isNumeric>{exists || '-'}</Td>
                        <Td isNumeric>{skipped || '-'}</Td>
                      </Tr>
                    ),
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const calcTimeDifference = (stats: Item['stats'], index: number): string => {
  const current = stats?.at(index)
  const prev = stats?.at(index - 1)

  if (current && prev) {
    return formatTime(
      current.mostRecentRecordDate,
      prev.mostRecentRecordDate.getTime(),
    )
  }
  return ''
}
