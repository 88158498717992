import {
  Box,
  Card,
  CardBody,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import React from 'react'
import { MdDelete, MdEdit, MdSettings } from 'react-icons/md'
import Loader from '../../../../Loader'
import { Title } from '../../../../utils'
import { useOrganizationExternalApi } from '../../../useExternalApi'
import CreateTokenModal from './CreateTokenModal'
import DeleteTokenModal from './DeleteTokenModal'
import { EditTokenModal } from './EditTokenModal'
import { MultiOrgConfigModal } from './MultiOrgConfigModal'

type Props = { organizationUid: string }

const ExternalApi: React.FC<Props> = ({ organizationUid }) => {
  const {
    data = [],
    isLoading,
    isError,
    error,
  } = useOrganizationExternalApi(organizationUid)
  const [openDeleteModal] = useModal(DeleteTokenModal)
  const [openCreateModal] = useModal(CreateTokenModal)
  const [openEditTokenModal] = useModal(EditTokenModal)
  const [openMultiOrgConfigModal] = useModal(MultiOrgConfigModal)

  if (isLoading) return <Loader />
  if (isError) return <Text>{error.message}</Text>

  return (
    <Card>
      <CardBody>
        <Title>External Api Tokens</Title>
        {data.map((d) => (
          <Box my="2" key={d.tokenId}>
            <Text display="inline-block">{d.name || d.tokenId}</Text>
            <IconButton
              aria-label="Edit token"
              variant="ghost"
              icon={<MdEdit />}
              onClick={() => {
                openEditTokenModal({
                  externalApiToken: d,
                  organizationUid,
                })
              }}
            />
            <Tooltip
              label="Configure multiple organizations"
              hasArrow
              placement="top"
            >
              <Box display="inline-block">
                <IconButton
                  aria-label="Configure multiple organizations"
                  variant="ghost"
                  icon={<MdSettings />}
                  onClick={() => {
                    openMultiOrgConfigModal({
                      tokenId: d.tokenId,
                      name: d.name,
                      organizationUid,
                      additionalOrganizations: d.additionalOrganizations || [],
                    })
                  }}
                />
              </Box>
            </Tooltip>
            <IconButton
              aria-label="Delete token"
              variant="ghost"
              icon={<MdDelete />}
              onClick={() => {
                openDeleteModal({ token: d.tokenId, organizationUid })
              }}
            />
            <Text>{d.created.toLocaleString()}</Text>
          </Box>
        ))}
        <Button
          width="100%"
          primary
          isLoading={isLoading}
          onClick={() =>
            openCreateModal({
              organizationUid: organizationUid,
            })
          }
        >
          Create External API Token
        </Button>
      </CardBody>
    </Card>
  )
}

export default ExternalApi
