import { Button } from '@capturi/ui-components'
import { SelectOption } from '@capturi/ui-select'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  chakra,
  useToast,
} from '@chakra-ui/react'
import { FC, useMemo, useState } from 'react'
import { useMasterdata } from '../../../../shared/useMasterdata'
import { useOrganizations } from '../../../../shared/useOrganizations'
import { noop } from '../../../../utils'
import {
  useSetMultipleOrganizations,
  useUpdateTokenWithAdditionalOrgs,
} from '../../../useExternalApi'
import { AdditionalOrgs } from './AdditionalOrgs'

export type AdditionalOrg = {
  organizationUid: string
  name: string
  filter: string
}

type Props = {
  tokenId: string
  name: string
  organizationUid: string
  additionalOrganizations: AdditionalOrg[]
} & BaseModalComponentProps

export const MultiOrgConfigModal: FC<Props> = ({
  tokenId,
  name,
  organizationUid,
  additionalOrganizations,
  onClose,
}) => {
  const { data: organizations } = useOrganizations()
  const { data: allMasterData } = useMasterdata()
  const { mutate: updateToken, isPending } =
    useUpdateTokenWithAdditionalOrgs(organizationUid)
  const toast = useToast()

  const [additionalOrgs, setAdditionalOrgs] = useState<
    AdditionalOrg[] | undefined
  >(additionalOrganizations || [])

  const currentOrg = useMemo(
    () => allMasterData?.find((d) => d.externalId === organizationUid),
    [allMasterData, organizationUid],
  )

  const { mutate: setMultipleOrganizations, isPending: isMultipleOrgPending } =
    useSetMultipleOrganizations(organizationUid)

  const orgsByCustomerKey = useMemo(() => {
    const orgSet = new Map(organizations?.map((org) => [org.uid, org]))
    const allMasterDataWithCustomerKey = allMasterData?.filter(
      (item) => item.customerKey === currentOrg?.customerKey,
    )
    return allMasterDataWithCustomerKey?.reduce<SelectOption[]>(
      (result, masterData) => {
        const matchingOrg = orgSet.get(masterData.externalId)

        if (matchingOrg) {
          result.push({
            value: matchingOrg.uid,
            label: matchingOrg.name,
          })
        }
        return result
      },
      [],
    )
  }, [allMasterData, currentOrg?.customerKey, organizations])

  const handleUpdateToken = () => {
    updateToken(
      {
        tokenId,
        name,
        organizationUid,
        additionalOrganizations: additionalOrgs || [],
      },
      {
        onSuccess: () => {
          toast({
            title: 'Additional organizations updated',
            description: 'Additional organizations updated successfully',
            status: 'success',
          })
          setMultipleOrganizations(
            {
              tokenId,
              multipleOrganizations: true,
            },
            {
              onSuccess: () => {
                onClose()
              },
              onError: (error) => {
                toast({
                  title: 'Error updating multiple organizations',
                  description: error.message,
                  status: 'error',
                })
              },
            },
          )
        },
        onError: (error) => {
          toast({
            title: 'Error updating additional organizations ',
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }
  const handleUpdateOrg = (index: number, org: AdditionalOrg) => {
    setAdditionalOrgs((prev) => {
      const newOrgs = [...(prev || [])]
      newOrgs[index] = org
      return newOrgs
    })
  }
  return (
    <Modal
      isOpen={true}
      onClose={isPending || isMultipleOrgPending ? noop : onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Additional orgs for {name}</ModalHeader>
        <ModalBody>
          <FormControl mt={2}>
            <FormLabel>
              Additional orgs
              <Text fontSize="xs" color="textMuted" fontWeight="normal">
                Select additional organizations below to use this token across
                multiple organizations.
              </Text>
            </FormLabel>
            <AdditionalOrgs
              values={orgsByCustomerKey || []}
              defaultValue={
                additionalOrgs?.map((org) => ({
                  value: org.organizationUid,
                  label: org.name,
                })) || []
              }
              onChange={(values) => {
                setAdditionalOrgs(
                  values.map((x, index) => ({
                    organizationUid: x.organisationUid,
                    name: x.name,
                    filter: additionalOrgs?.[index]?.filter || '',
                  })),
                )
              }}
            />
          </FormControl>
          <VStack overflowY="auto" maxH="750px" mt={4}>
            {additionalOrgs?.map((org, index) => (
              <FormControl mt={2} key={org.organizationUid}>
                <FormLabel>
                  <chakra.span fontWeight="normal">Filter for </chakra.span>
                  {org.name}
                </FormLabel>
                <Textarea
                  isDisabled={isPending || isMultipleOrgPending}
                  onChange={(e) =>
                    handleUpdateOrg(index, {
                      ...org,
                      filter: e.target.value,
                    })
                  }
                  value={org.filter}
                />
              </FormControl>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            onClick={onClose}
            isDisabled={isPending || isMultipleOrgPending}
          >
            Cancel
          </Button>
          <Button
            isLoading={isPending || isMultipleOrgPending}
            primary
            onClick={handleUpdateToken}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
