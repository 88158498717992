import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { FC } from 'react'

type Props = {
  values: SelectOption[]
  defaultValue: SelectOption[]
  onChange: (value: { organisationUid: string; name: string }[]) => void
}

export const AdditionalOrgs: FC<Props> = ({
  values,
  onChange,
  defaultValue,
}) => {
  const handleChange = (values: OnChangeValue<SelectOption, boolean>): void => {
    if (Array.isArray(values)) {
      onChange(values.map((x) => ({ organisationUid: x.value, name: x.label })))
    }
  }
  return (
    <Select
      options={values}
      defaultValue={defaultValue}
      onChange={handleChange}
      isMulti
    />
  )
}
