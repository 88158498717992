import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

export const CopilotConfigModal: FC<BaseModalComponentProps> = ({
  onClose,
}) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Trans>Copilot config</Trans>
        </ModalHeader>
      </ModalContent>
    </Modal>
  )
}
