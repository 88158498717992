import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { noop } from '../../../../utils'
import {
  ExternalIntegration,
  useUpdateTokenName,
} from '../../../useExternalApi'

type Props = {
  externalApiToken: ExternalIntegration
  organizationUid: string
} & BaseModalComponentProps

export const EditTokenModal: FC<Props> = ({
  externalApiToken,
  organizationUid,
  onClose,
}) => {
  const [name, setName] = useState(externalApiToken.name)
  const { mutate: updateTokenName, isPending } =
    useUpdateTokenName(organizationUid)
  const toast = useToast()

  const handleUpdateTokenName = () => {
    updateTokenName(
      { ...externalApiToken, name },
      {
        onSuccess: () => {
          toast({
            title: 'Token name updated',
            status: 'success',
            description: 'The token name has been updated successfully',
          })
          onClose()
        },
        onError: (error) => {
          toast({
            title: 'Error updating token name',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  return (
    <Modal isOpen={true} onClose={isPending ? noop : onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton isDisabled={isPending} />
        <ModalHeader>Edit Token</ModalHeader>
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                value={name}
                isDisabled={isPending}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Token</FormLabel>
              <Text fontWeight="500">{externalApiToken.tokenId}</Text>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button isDisabled={isPending} onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            onClick={() => {
              handleUpdateTokenName()
            }}
            isLoading={isPending}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
