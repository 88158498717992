import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { noop } from '../../../../utils'
import { useCreateToken } from '../../../useExternalApi'

type Props = { organizationUid: string } & BaseModalComponentProps

const CreateTokenModal: React.FC<Props> = ({ onClose, organizationUid }) => {
  const toast = useToast()

  const copyTokenToClipboard = (): void => {
    navigator.clipboard.writeText(newToken)
    toast({ title: 'Copied token to clipboard', status: 'info' })
  }
  const [newToken, setNewToken] = useState('')
  const [tokenName, setTokenName] = useState('')
  const { mutate: createToken, isPending } = useCreateToken(organizationUid)

  const handleCreateToken = (name: string) => {
    createToken(name, {
      onSuccess: (data) => setNewToken(data.secret),
      onError: (error) => {
        toast({
          title: 'Could not create token',
          description: error.message,
          status: 'error',
        })
      },
    })
  }

  return (
    <Modal isOpen onClose={isPending ? noop : onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton isDisabled={isPending} />
          <ModalHeader>Create secret token</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Token name</FormLabel>
              <Input
                isDisabled={isPending || !!newToken}
                value={tokenName}
                onChange={(e) => setTokenName(e.target.value)}
              />
            </FormControl>
            {newToken && (
              <Box>
                <Text fontWeight="bold">Token is only vissible once!</Text>
                <Text
                  mt="2"
                  p="2"
                  bg="gray.100"
                  borderRadius="md"
                  userSelect="all"
                  fontFamily="mono"
                >
                  {newToken}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button variant="ghost" onClick={onClose} isDisabled={isPending}>
              Close
            </Button>
            {!newToken ? (
              <Button
                primary
                isLoading={isPending}
                isDisabled={!!newToken}
                onClick={() => handleCreateToken(tokenName)}
              >
                Create token
              </Button>
            ) : (
              <Button variant="ghost" primary onClick={copyTokenToClipboard}>
                Copy
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CreateTokenModal
