import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import { Box, Link as ChakraLink, HStack, StackDivider } from '@chakra-ui/react'
import React from 'react'
import {
  NavLinkProps,
  Navigate,
  Outlet,
  NavLink as RRNavLink,
  Route,
  Routes,
} from 'react-router'
import AccessOverview from './AccessOverview'
import { AskAiQuestionsPage } from './AskAiQuestions'
import { CurrentlyRunning } from './CurrentlyRunning'
import DeletionReports from './DeletionReports'
import Heimdal from './Heimdal'
import IntegrationsOverview from './IntegrationsOverview'
import KeyTopics from './KeyTopics/KeyTopics'
import KeyTopicsBlacklist from './KeyTopicsBlacklist/KeyTopicsBlacklist'
import KeyTopicsWordCloudBlacklist from './KeyTopicsWordCloudBlacklist/KeyTopicsWordCloudBlacklist'
import Loader from './Loader'
import { MasterdataProvision } from './MasterdataProvision'
import Options from './Options'
import Organization from './Organization'
import OrganizationPage from './Organization/OrganizationPage'
import Access from './Organization/tabs/Access'
import AuthSettings from './Organization/tabs/AuthSettings'
import Config from './Organization/tabs/Config'
import Conversations from './Organization/tabs/Conversations'
import { Copilot } from './Organization/tabs/Copilot'
import RemoveLabels from './Organization/tabs/LabelEditor'
import Mappings from './Organization/tabs/Mappings'
import { MetabaseReports } from './Organization/tabs/MetabaseReports'
import GlobalReports from './Organization/tabs/MetabaseReports/GlobalReports/GlobalReports'
import OrganizationInsights from './Organization/tabs/OrganizationInsights'
import Teams from './Organization/tabs/Teams'
import Users from './Organization/tabs/Users'
import WordCloud from './Organization/tabs/WordCloud'
import { CustomProperties } from './Organization/tabs/custom-properties'
import OrganizationSettingsOverview from './OrganizationSettingsOverview'
import RepeatCallBlacklistWrapper from './RepeatCallsBlacklist/index'
import ReprocessConversations from './ReprocessConversations'
import StopWords from './StopWords'
import UploadTranscription from './createTranscription/UploadTranscription'
import ViewTranscription from './createTranscription/ViewTranscription'
import Conversation from './info/Conversation'
import ImportErrors from './info/ImportErrors'
import User from './info/User'

const NavLink: React.FC<NavLinkProps> = (props) => (
  <RRNavLink
    {...props}
    style={({ isActive }) => ({ fontWeight: isActive ? 500 : 400 })}
  />
)

const SuperPowers: React.FC = () => {
  usePageTitle('Superpowers')
  return (
    <Box h="calc(100% - 500px)" w="100%">
      <HStack
        spacing={2}
        divider={<StackDivider />}
        shouldWrapChildren
        align="center"
        color="gray.600"
        transform="translateY(-30px)"
        flexWrap="wrap"
      >
        <NavLink to="organization">Organizations</NavLink>
        <ChakraLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.capturi.ai/superpowers/betalabs/"
        >
          BetaLabs
        </ChakraLink>
        <NavLink to="options">Options</NavLink>
        <NavLink to="conversation">Conversation</NavLink>
        <NavLink to="user">User</NavLink>
        <NavLink to="import-errors">Import Errors</NavLink>
        <NavLink to="stop-words">Trends Stop Words</NavLink>
        <NavLink to="key-topics-stop-words">Word Cloud</NavLink>
        <NavLink to="key-topics-blacklist">Key topics blacklist</NavLink>
        <NavLink to="reprocess-conversations">Reprocess</NavLink>
        <NavLink to="integrations">Integrations Overview</NavLink>
        <NavLink to="create-transcription">Create Transcription</NavLink>
        <NavLink to="heimdal">Heimdal</NavLink>
        <NavLink to="access-overview">Access Overview</NavLink>
        <NavLink to="ask-ai-config">Ask AI Config</NavLink>
        <NavLink to="global-reports-page">Global Reports</NavLink>
        <NavLink to="organization-settings-overview">
          Organization Settings Overview
        </NavLink>
        <NavLink to="deletion-reports">Deletion Reports</NavLink>
        <NavLink to="currently-running">Currently Running</NavLink>
        <NavLink to="repeat-calls-blacklist">Repeat calls blacklist</NavLink>
        <NavLink to="masterdata-provision">Masterdata Provisions</NavLink>
      </HStack>
      <React.Suspense fallback={<Loader />}>
        <Outlet />
      </React.Suspense>
    </Box>
  )
}

const SuperPowersRouter: React.FC = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<SuperPowers />}>
            <Route index element={<Navigate to="organization" replace />} />
            {/* <Route path="monitor" element={<Monitor />} /> */}
            {/* TODO: should be moved under Organization component */}
            <Route path="organization" element={<Organization />}>
              <Route path=":uid" element={<OrganizationPage />}>
                <Route index element={<Navigate to="config" replace />} />
                <Route path="config" element={<Config />} />
                <Route path="custom-props" element={<CustomProperties />} />
                <Route path="insights" element={<OrganizationInsights />} />
                <Route path="conversations" element={<Conversations />} />
                <Route path="mappings" element={<Mappings />} />
                <Route path="users" element={<Users />} />
                <Route path="teams" element={<Teams />} />
                <Route path="wordcloud" element={<WordCloud />} />
                <Route path="auth-settings" element={<AuthSettings />} />
                <Route path="label-editor" element={<RemoveLabels />} />
                <Route path="metabase-reports" element={<MetabaseReports />} />
                <Route path="copilot" element={<Copilot />} />
                <Route path="key-topics" element={<KeyTopics />} />
                <Route path="access" element={<Access />} />
              </Route>
            </Route>
            <Route path="options" element={<Options />} />
            <Route path="conversation" element={<Conversation />} />
            <Route path="user" element={<User />} />
            <Route path="import-errors" element={<ImportErrors />} />
            <Route path="stop-words" element={<StopWords />} />
            <Route path="global-reports-page" element={<GlobalReports />} />

            <Route
              path="key-topics-stop-words"
              element={<KeyTopicsWordCloudBlacklist />}
            />
            <Route
              path="key-topics-blacklist"
              element={<KeyTopicsBlacklist />}
            />
            <Route path="integrations" element={<IntegrationsOverview />} />
            <Route
              path="create-transcription"
              element={<UploadTranscription />}
            />
            <Route
              path="create-transcription/:resultUid/:spokenLanguage"
              element={<ViewTranscription />}
            />
            <Route path="heimdal" element={<Heimdal />} />
            <Route path="access-overview" element={<AccessOverview />} />
            <Route path="ask-ai-config" element={<AskAiQuestionsPage />} />

            <Route
              path="reprocess-conversations"
              element={<ReprocessConversations />}
            />
            <Route
              path="organization-settings-overview"
              element={<OrganizationSettingsOverview />}
            />
            <Route path="deletion-reports" element={<DeletionReports />} />
            <Route path="currently-running" element={<CurrentlyRunning />} />
            <Route
              path="repeat-calls-blacklist"
              element={<RepeatCallBlacklistWrapper />}
            />
            <Route
              path="masterdata-provision"
              element={<MasterdataProvision />}
            />
          </Route>
        </Routes>
      </React.Suspense>
    </ErrorBoundary>
  )
}
export default SuperPowersRouter
