import { ResponseError, downloadBlob } from '@capturi/request'
import request from '@capturi/request/src/request'
import { Button, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Grid, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React, { Fragment } from 'react'
import Loader from '../Loader'
import DeletionReportDetailsModal from './DeletionReportDetailsModal'

type Response = {
  organizations: { uid: string; name: string; deleted: Date }[]
}

const DeletionReports: React.FC = () => {
  const [open] = useModal(DeletionReportDetailsModal)
  const { data, isLoading, error } = useQuery<Response, ResponseError>({
    queryKey: ['superpowers', 'deletionReports'],
    queryFn: () => request.get('systemadmin/organization/deleted'),
  })

  const toast = useToast()

  const handleDownload = async (uid: string, name: string) => {
    try {
      await downloadBlob(
        `systemadmin/organization/${uid}/deletion-report-as-text`,
        `deleteion report for ${name}.txt`,
        {
          method: 'post',
        },
      )
    } catch (error) {
      if (error instanceof ResponseError) {
        toast({
          status: 'error',
          title: 'Could not download report',
          description: error.message,
        })
      } else {
        toast({ status: 'error', title: 'Could not download report' })
      }
    }
  }

  if (error) return <Text>{error.message}</Text>
  if (isLoading || !data) return <Loader />

  return (
    <div>
      <Text fontSize="2xl" mb="4">
        Deletion Reports
      </Text>
      <Grid
        gridTemplateColumns="fit-content(40%) fit-content(100%) fit-content(40%)"
        columnGap="8"
        rowGap="2"
      >
        {data.organizations.map((d) => (
          <Fragment key={d.uid + d.deleted.getTime()}>
            <div>{d.deleted.toLocaleString('da-DK')}</div>
            <div>
              <Button
                variant="link"
                primary
                onClick={() => open({ organizationUid: d.uid })}
              >
                {d.name}
              </Button>
            </div>
            <Button
              variant="ghost"
              onClick={() => handleDownload(d.uid, d.name)}
            >
              Download
            </Button>
          </Fragment>
        ))}
      </Grid>
    </div>
  )
}

export default DeletionReports
