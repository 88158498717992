import { useModal } from '@capturi/use-modal'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { MdTableRows } from 'react-icons/md'
import { Link } from 'react-router'
import type { Item } from './Column'
import { DetailsModal } from './DetailsModal'
import { formatTime } from './formatTime'

type Props = Item & {
  color: string
}

const calcTotal = (stats: Item['stats']) => {
  return (stats || []).reduce(
    (memo, s) => {
      memo.created = memo.created + s.created
      memo.fetched = memo.fetched + s.fetched
      memo.prepared = memo.prepared + s.prepared
      memo.exists = memo.exists + s.exists
      memo.skipped = memo.skipped + s.skipped
      memo.failed = memo.failed + s.failed

      return memo
    },
    {
      fetched: 0,
      exists: 0,
      skipped: 0,
      prepared: 0,
      created: 0,
      failed: 0,
    },
  )
}

export const ItemCard: React.FC<Props> = ({
  integrationKey,
  lastRunEnded,
  lastRunStarted,
  color,
  organizationUid,
  isRunning,
  stats,
  currentStats,
}) => {
  const [open] = useModal(DetailsModal)
  const total = useMemo(() => calcTotal(stats), [stats])
  const newest = stats?.at(0)?.continuationToken || ''
  return (
    <Card
      variant={isRunning ? 'filled' : 'elevated'}
      bg={isRunning ? 'green.100' : undefined}
      size="sm"
      mb="4"
      mx="1"
      borderLeft={`solid 3px ${color}`}
    >
      <CardHeader display="flex" justifyContent="space-between">
        <Link to={`/superpowers/organization/${organizationUid}`}>
          <Heading onClick={() => {}} size="xl">
            {integrationKey}
          </Heading>
        </Link>
        {!isRunning && (
          <IconButton
            aria-label="view details"
            icon={<MdTableRows />}
            onClick={() => open({ stats, integrationKey })}
            variant={'ghost'}
          />
        )}
      </CardHeader>
      <CardBody>
        {isRunning && lastRunStarted && (
          <Text>Is Running: {formatTime(lastRunStarted)}</Text>
        )}
        {!isRunning && lastRunEnded && (
          <Text>Time in queue: {formatTime(lastRunEnded)}</Text>
        )}

        {!isRunning && stats?.length && total && (
          <Box mt="2">
            <Text fontWeight="semibold">Total last {stats.length} rounds</Text>

            <Text>Fetched: {total.fetched}</Text>
            <Text>Created: {total.created}</Text>
            <Text>Continuation Token {newest.toLocaleString()}</Text>
          </Box>
        )}
        {currentStats && (
          <Box mt="2">
            <Text>Current round</Text>
            <Text>fetched {currentStats.fetched}</Text>
            <Text>exists {currentStats.exists}</Text>
            <Text>failed {currentStats.failed}</Text>
            <Text>skipped {currentStats.skipped}</Text>
            <Text>prepared {currentStats.prepared}</Text>
            <Text>created {currentStats.created}</Text>
          </Box>
        )}
      </CardBody>
    </Card>
  )
}
